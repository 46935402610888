import React from 'react';
import { WithPhrases } from '@tidsbanken/phrases';
import { debounce } from '../helpers/debounce';
import { OmniSearchResult } from './core-utvalg';
import './SoekTreff.less';

export enum TreffType {
    pill_activity,
    pill_customer,
    pill_project,
    pill_employee,
}

interface ITreff {
    begrep: TreffType;
    id: number | string;
    navn: string;
}

interface ISoekTreffProps {
    sokestreng: string;
    soker: boolean;
}

interface ISoekTreffState {
    antallTreff: number;
    treffAktivitet: ITreff[];
    treffKunder: ITreff[];
    treffOppgaver: ITreff[];
    treffAnsatte: ITreff[];
}

class SoekTreff extends React.Component<ISoekTreffProps, ISoekTreffState> {
    constructor(props: ISoekTreffProps) {
        super(props);
        this.state = {
            antallTreff: 0,
            treffAktivitet: [],
            treffKunder: [],
            treffOppgaver: [],
            treffAnsatte: [],
        };
    }

    componentDidMount() {
        if (this.props.sokestreng.length > 0) {
            this.soekDebounced(this.props.sokestreng);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps: ISoekTreffProps) {
        if (nextProps.sokestreng.length > 0) {
            this.soekDebounced(nextProps.sokestreng);
        } else {
            this.setState({
                antallTreff: 0,
                treffAktivitet: [],
                treffKunder: [],
                treffOppgaver: [],
                treffAnsatte: [],
            });
        }
    }

    render() {
        return (
            <WithPhrases domain="meny">
                {(p) => (
                    <div className={'SoekTreff ' + (this.props.soker ? 'soker' : '')}>
                        <ul className="TreffListe">
                            {this.props.sokestreng &&
                                [
                                    ...this.state.treffAktivitet,
                                    ...this.state.treffAnsatte,
                                    ...this.state.treffOppgaver,
                                    ...this.state.treffKunder,
                                ].map((x) => {
                                    return (
                                        <a key={x.begrep + '-' + x.id} href={this.treffUrl(x)}>
                                            <li className={'treff-' + TreffType[x.begrep]}>
                                                <span className="begrep">{p(TreffType[x.begrep])}</span>
                                                <span className="id">
                                                    {this.boldify(x.id.toString(), this.props.sokestreng)}
                                                </span>
                                                <span className="navn">
                                                    {this.boldify(x.navn, this.props.sokestreng)}
                                                </span>
                                            </li>
                                        </a>
                                    );
                                })}
                        </ul>
                        <div className="sokestatus">
                            {this.state.antallTreff ? (
                                this.props.sokestreng ? (
                                    <span>{p('top_search_n_hits', `${this.state.antallTreff}`)}</span>
                                ) : (
                                    <span>{p('top_search_no_hits')}</span>
                                )
                            ) : (
                                <span>{p('top_search_no_hits')}</span>
                            )}
                        </div>
                    </div>
                )}
            </WithPhrases>
        );
    }

    private soek = async (sokestreng: string) => {
        const myInit = this.getInit();
        const searchUrl =
            window.location.hostname === 'min.tidsbanken.net'
                ? `https://api-me.tidsbanken.net/omnisearch`
                : `https://api-me-sandkasse.tidsbanken.net/omnisearch`;

        const result = await fetch(`${searchUrl}?search=${sokestreng}`, myInit);
        const resultJson = await result.json();
        const { Activities, Customers, Employees, Projects } = resultJson as OmniSearchResult;

        this.setState({
            antallTreff: Activities.length + Customers.length + Employees.length + Projects.length,
            treffAktivitet: Activities.map((a) => ({ begrep: TreffType.pill_activity, id: a.Id, navn: a.Name })),
            treffKunder: Customers.map((c) => ({ begrep: TreffType.pill_customer, id: c.Id, navn: c.Name })),
            treffOppgaver: Projects.map((p) => ({ begrep: TreffType.pill_project, id: p.Id, navn: p.Name })),
            treffAnsatte: Employees.map((e) => ({ begrep: TreffType.pill_employee, id: e.Id, navn: e.Name })),
        });
    };

    private soekDebounced = debounce(this.soek, 300);

    private getInit() {
        const myHeaders = new Headers();
        const cred: RequestCredentials = 'include';
        const requestmode: RequestMode = 'cors';
        const myInit: RequestInit = {
            method: 'GET',
            headers: myHeaders,
            mode: requestmode,
            credentials: cred,
        };
        return myInit;
    }

    private boldify(tekst: string, fet: string) {
        const fetstart = tekst.toLowerCase().indexOf(fet.toLowerCase());
        if (fetstart === -1) {
            return tekst;
        } else {
            return (
                <span>
                    {tekst.substring(0, fetstart)}
                    <strong>{tekst.substring(fetstart, fetstart + fet.length)}</strong>
                    {tekst.substring(fetstart + fet.length)}
                </span>
            );
        }
    }

    private treffUrl(x: ITreff) {
        switch (x.begrep) {
            case TreffType.pill_activity:
                return '/akt.asp?id=' + x.id;
            case TreffType.pill_customer:
                return '/kartotek_kunder.asp?id=' + x.id;
            case TreffType.pill_project:
                return '/kartotek_prosjekter.asp?id=' + x.id;
            case TreffType.pill_employee:
                return '/kartotek_ansatte.asp?id=' + x.id;
            default:
                return '';
        }
    }
}

export default SoekTreff;
